<template>
	<div class="wrap">
		<div class="title title-vertical flex-space-between">
			<span>{{ pathName == '' ? $route.meta.pathName : pathName }}</span>
		</div>
		<List @itemClick="listClick"></List>
	</div>
</template>

<script>
import List from '@/components/list/list.vue'

export default {
	props: ['index'],
	components: {List},
	data() {
		return {
			asideIndex: 0,
			pathName: ''
		}
	},

	mounted() {
	},

	methods: {
		listClick() {
			this.$router.push({
				name: 'podetail',
				params: {
					index: this.asideIndex
				}
			})
		}
	},
	watch: {
		index: {
			handler: function (val) {
				this.asideIndex = val
				this.pathName = this.$route.meta.pathName
				console.log(val)
			},
			immediate: true
		}
	}
}
</script>

<style lang="less" scoped>
.wrap {
	padding: 30px 0 69px 0;
	background: #fff;
}
</style>
